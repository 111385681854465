// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Input,
  Label,
  Card,
  CardBody,
} from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

// import images
// import contactImg from "../../../assets/images/contact-detail.jpg";
import bg01 from '../../../assets/images/corporate/Banner1.jpg';

class PageContactThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Contactvisible: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendMail.bind(this);
    this.callNumber.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ Contactvisible: true });
    //api call
  }

  componentDidMount() {
    document.body.classList = '';
    document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
        document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
        document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
        document
          .querySelector('.settingbtn')
          ?.classList.add('btn-soft-primary');
      }
    }
  };

  sendMail() {
    window.location.href = 'mailto:contact@example.com';
  }

  callNumber() {
    window.location.href = 'tel:+152534-468-854';
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="bg-half-170 bg-light d-table w-100"
          style={{ background: `url(${bg01}) center center`, paddingTop: '100px' }}
        >
          <div className="bg-overlay bg-overlay-white"></div>
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0">Contact Us</h4>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item">
                    <Link to="/home">Kashyap Diamond</Link>
                  </li>{' '}
                  {/* <li className="breadcrumb-item"><Link to="#"></Link></li>{" "} */}
                  <li className="breadcrumb-item">
                    <Link to="#">Contact</Link>
                  </li>{' '}
                  {/* <li className="breadcrumb-item active" aria-current="page">Contact Three</li> */}
                </ul>
              </nav>
            </div>
          </Container>
        </section>

        <div className="form-icon position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <div className="title-heading text-center mt-4">
                <h1 className="heading title-dark mb-4">
                Have Question ? Get in touch!
                </h1>
                <p className="para-desc mx-auto title-dark">
                503, Jin Ratna, L.B Cross Road, Mahidharpura Diamond Market, Surat
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <section className="section" style={{paddingTop:"25px"}}>
          <Container>
            <Row>
              <Col lg={4} md={6} className="mt-4 pt-2">
                <Card className="shadow rounded border-0">
                  <CardBody className="py-5">
                    <h4 className="card-title">Send Us Message !</h4>
                    <div className="custom-form mt-3">
                      <div id="message"></div>
                      <Alert
                        color="info"
                        isOpen={this.state.Contactvisible}
                        toggle={() => {
                          this.setState({
                            Contactvisible: !this.state.Contactvisible,
                          });
                        }}
                      >
                        Contact details send successfully.
                      </Alert>
                      <Form
                        method="post"
                        onSubmit={this.handleSubmit}
                        name="contact-form"
                        id="contact-form"
                      >
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Name <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="name"
                                id="name"
                                type="text"
                                className="form-control ps-5"
                                placeholder="Name :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Email{' '}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="mail"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="email"
                                id="email"
                                type="email"
                                className="form-control ps-5"
                                placeholder="Email :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Subject </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="book"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="subject"
                                id="subject"
                                type="text"
                                className="form-control ps-5"
                                placeholder="Subject :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Comments</Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="message-circle"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <textarea
                                name="comments"
                                id="comments"
                                rows="4"
                                className="form-control ps-5"
                                placeholder="Message :"
                              ></textarea>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} className="text-center">
                            <div className="d-grid">
                              <input
                                type="submit"
                                id="submit"
                                name="send"
                                className="submitBnt btn btn-primary btn-block"
                                style={{
                                  background: '#00030a',
                                  borderColor: '#00030a',
                                }}
                                value="Send Message"
                              />
                            </div>
                            <div id="simple-msg"></div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={8} md={6} className="ps-md-3 pe-md-3 mt-4 pt-2">
                <Card className="map map-height-two rounded map-gray border-0">
                  <CardBody className="p-0">
                    <iframe
                      title="test"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d738.089187503493!2d72.83235231919066!3d21.202629418794903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04ef5ec2c666b%3A0x6fecfc53d9591d0a!2sLB%20Cross%20Rd%2C%20Mahidharpura%2C%20Haripura%2C%20Surat%2C%20Gujarat%20395008!5e0!3m2!1sen!2sin!4v1707590864388!5m2!1sen!2sin"
                      style={{ border: 0 }}
                      className="rounded"
                      allowFullScreen=""
                    ></iframe>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          {/* <Container className="mt-100 mt-60">
            <Row>
              <Col md={4}>
                <Card className="border-0 text-center features feature-primary feature-clean">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-phone d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-4">
                    <h5 className="fw-bold">Phone</h5>
                    <p className="text-muted">
                      Start working with Landrick that can provide everything
                    </p>
                    <Link
                      to="#"
                      onClick={this.callNumber}
                      className="read-more"
                    >
                      +152 534-468-854
                    </Link>
                  </div>
                </Card>
              </Col>

              <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <Card className="border-0 text-center features feature-primary feature-clean">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-envelope d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-4">
                    <h5 className="fw-bold">Email</h5>
                    <p className="text-muted">
                      Start working with Landrick that can provide everything
                    </p>
                    <Link
                      to="#"
                      onClick={this.sendMail}
                      className="read-more"
                    >
                      contact@example.com
                    </Link>
                  </div>
                </Card>
              </Col>

              <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <Card className="border-0 text-center features feature-primary feature-clean">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-map-marker d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-4">
                    <h5 className="fw-bold">Location</h5>
                    <p className="text-muted">
                      C/54 Northwest Freeway, Suite 558, <br />
                      Houston, USA 485
                    </p>
                    <Link to="#" className="video-play-icon read-more lightbox">
                      View on Google map
                    </Link>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container> */}
        </section>
      </React.Fragment>
    );
  }
}
export default PageContactThree;
